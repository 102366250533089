<template>
	<section class="cupones-vendedor pb-4 h-100">
		<div class="lista-carpetas bg-white br-8 p-3">
			<!-- buscar + agregar -->
			<div class="row mx-0 align-items-center">
				<div class="col px-0">
					<el-input v-model="buscarCupon" placeholder="Buscar Carpeta" class="br-20 w-100 h-32px" />
				</div>
				<div class="col-auto">
					<el-tooltip placement="bottom" content="Crear Carpeta de cupones" effect="light">
						<div class="bg-general d-middle-center wh-32px text-white br-8 cr-pointer shadow"
							@click="crearCarpeta">
							<i class="icon-folder-multiple-plus f-20" />
						</div>
					</el-tooltip>
				</div>
			</div>
			<!-- listado carpetas -->
			<div class="select-cupon custom-scroll overflow-auto mt-3">
				<div v-for="(c, index) in carpetas.filter(data => !buscarCupon || data.nombre.toLowerCase().includes(buscarCupon.toLowerCase()))"
					:key="index" class="d-middle border-bottom px-2 py-2 cr-pointer" @click="seleccionarCarpeta(c)">
					<div class="br-10 h-30px w-8px" :class="idCarpetaActiva == c.id ? 'bg-general' : ''" />
					<div class="col text-general">
						<p :class="`${idCarpetaActiva == c.id ? 'text-general' : 'text-general2'} tres-puntos f-15`">{{
							c.nombre }}</p>
					</div>
					<div class="col-auto px-1">
						<div class="d-middle-center bg-general3 px-1 rounded-pill h-28px wm-68px text-white">
							<i class="icon-cupon mr-2 f-13 pr-1" />
							{{ c.cant_cupones }}
						</div>
					</div>
					<i class="icon-angle-right f-22" :class="idCarpetaActiva == c.id ? 'text-general' : 'text-gris'" />
				</div>
			</div>
		</div>
		<div class="lista-cupones bg-white br-8 overflow-hidden">
			<div v-if="idCarpetaActiva">
				<div class="d-flex mx-0 pb-2 my-2 border-bottom aling-items-center">
					<div class="col-auto text-general d-middle f-17">
						{{ carpetaSeleccionada.nombre }}
					</div>
					<div class="col-auto d-middle ml-auto">
						<span class="text-general mx-2">
							Carpeta {{ carpetaSeleccionada.estado ? 'Activa' : 'Inactiva' }}
						</span>
						<el-switch v-model="carpetaSeleccionada.estado" :active-value="1" :inactive-value="0"
							active-color="#000000" @change="cambiarEstadoCarpeta" />
					</div>
					<div class="col-auto d-middle px-2 ">
						<el-tooltip placement="bottom" content="Cambiar nombre" effect="light">
							<div class="d-middle-center btn-action border wh-32">
								<i class="icon-pencil-outline f-22 hover-icons" @click="crearCarpeta(false)" />
							</div>
						</el-tooltip>
					</div>
					<div class="col-auto d-middle px-2 ">
						<el-tooltip placement="bottom" content="Eliminar Carpeta" effect="light">
							<div class="d-middle-center btn-action border wh-32">
								<i class="icon-trash-empty f-22 hover-icons" @click="eliminarCarpeta" />
							</div>
						</el-tooltip>
					</div>
					<div class="d-middle-center bg-general text-white br-8 wh-32 mx-2 cr-pointer"
						@click="$router.push({ name: 'tendero.cupones.crear', params: { idCarpeta: idCarpetaActiva } })">
						<el-tooltip placement="bottom" content="Crear Cupón" effect="light">
							<i class="icon-plus f-17" />
						</el-tooltip>
					</div>
				</div>
				<div class="row mx-0">
					<div class="col-12 custom-scroll overflow-auto" style="height:calc(100vh - 192px)">
						<el-table :data="cuponesVigentes" header-row-class-name="text-general f-17" class="cr-pointer"
							style="width: 100%" @row-click="irDetalle">
							<el-table-column prop="nombre" label="Cupón vigente" min-width="200" />
							<el-table-column label="Descuento" class-name="text-center" min-width="150">
								<template slot-scope="{row}">
									<p> {{ row.descuento }}% </p>
								</template>
							</el-table-column>
							<el-table-column label="Usados" class-name="text-center" min-width="150">
								<template slot-scope="{row}">
									<div class="row mx-0 justify-center">
										<div class="col-auto">
											<p v-if="row.tipo === 1">{{ row.cant_usada }}</p>
											<p v-if="row.tipo === 2">{{ row.cant_usada }}({{ row.cant_usada_bono }})</p>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="Disponibles" class-name="text-center" min-width="150">
								<template slot-scope="{row}">
									<div class="row mx-0 justify-center">
										<div class="col-auto">
											<p v-if="row.limite_total === null">Sin Limite</p>
											<p v-if="row.limite_total !== null">{{ row.cant_usada }}/{{ row.limite_total }}
											</p>
										</div>
									</div>
								</template>
							</el-table-column>
						</el-table>
						<!-- Cupones vencidos -->
						<div class="row mx-0 py-4" />
						<div v-show="cuponesVencidos.length" class="row mx-0 mt-4">
							<div class="col-12">
								<el-table :data="cuponesVencidos" header-row-class-name="text-general f-17"
									class="cr-pointer" style="width: 100%" @row-click="irDetalle">
									<el-table-column prop="nombre" label="Cupón vencido" min-width="200" />
									<el-table-column label="Descuento" class-name="text-center" min-width="150">
										<template slot-scope="{row}">
											<p> {{ row.descuento }}% </p>
										</template>
									</el-table-column>
									<el-table-column label="Usados" class-name="text-center" min-width="150">
										<template slot-scope="{row}">
											<div class="row mx-0 justify-center">
												<div class="col-auto">
													<p v-if="row.tipo === 1">{{ row.cant_usada }}</p>
													<p v-if="row.tipo === 2">{{ row.cant_usada }}({{ row.cant_usada_bono }})
													</p>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="Disponibles" class-name="text-center" min-width="150">
										<template slot-scope="{row}">
											<div class="row mx-0 justify-center">
												<div class="col-auto">
													<p v-if="row.limite_total === null">Sin Limite</p>
													<p v-if="row.limite_total !== null">{{ row.limite_total - row.cant_usada}}</p>
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="col custom-scroll overflow-auto px-0 border cont-cupon h-100">
				<div class="text-center f-15 f-600 d-middle-center flex-column mx-auto h-100" style="width:225px;">
					<div class="d-middle-center bg-whitesmoke br-5" style="width:170px;height:170;">
						<img height="120" src="/img/no-imagen/click.svg" alt="" />
					</div>
					<p class="mt-4">Seleccione una de las carpetas activas del panel de la izquierda.</p>
				</div>
			</div>
		</div>
		<!-- Partials -->
		<modal-crear-carpeta ref="refModalCrearCarpeta" @edit="updateFolder" @update="updateList" />
		<modal-eliminar ref="refModalEliminarCarpeta" titulo="Eliminar carpeta"
			mensaje="Desea eliminar la carpeta de cupones?" @eliminar="handleDeleteFolder" />
	</section>
</template>

<script>
import Service from "~/services/cupones/cupones";
export default {
	components: {
		modalCrearCarpeta: () => import('./partials/modalCrearCarpeta')
	},
	data() {
		return {
			buscarCupon: '',
			idCarpetaActiva: null,
			carpetaSeleccionada: {},
			carpetas: [],
			cuponesVencidos: [],
			cuponesVigentes: []
		}
	},
	watch: {
		idCarpetaActiva() {
			this.getCuponesCarpeta()
		}
	},
	mounted() {
		this.getCarpetasCupones()
	},
	methods: {
		crearCarpeta(crear = true) {
			try {
				if (crear) {
					this.$refs.refModalCrearCarpeta.toggle();
					return
				}
				this.$refs.refModalCrearCarpeta.editar(this.carpetaSeleccionada);
			} catch (error) {
				return this.error_catch(e)
			}
		},
		seleccionarCarpeta(carpeta) {
			this.idCarpetaActiva = carpeta.id
			this.carpetaSeleccionada = carpeta
		},
		updateFolder(item) {
			this.carpetaSeleccionada.nombre = item.nombre;
		},
		updateList(item) {
			this.carpetas.push(item)
		},
		async getCarpetasCupones() {
			try {
				const { data } = await Service.listarCarpetas(0, 1);
				this.carpetas = data.carpetas;
			} catch (e) {
				return this.error_catch(e)
			}
		},
		async getCuponesCarpeta() {
			try {
				const { data } = await Service.listarCupones(this.idCarpetaActiva)
				this.cuponesVencidos = data.vencidos
				this.cuponesVigentes = data.vigentes
			} catch (error) {
				return this.error_catch(e)
			}
		},
		async cambiarEstadoCarpeta() {
			try {
				await Service.cambioEstadoCarpeta(this.idCarpetaActiva)
				this.notificacion('', 'El estado se la carpeta ha sido actualizado', 'success')
			} catch (e) {
				this.error_catch(e)
			}
		},
		async eliminarCarpeta() {
			try {
				this.$refs.refModalEliminarCarpeta.toggle()
			} catch (e) {
				this.error_catch(e)
			}
		},
		async handleDeleteFolder() {
			try {
				await Service.eliminarCarpeta(this.idCarpetaActiva)
				const idx = this.carpetas.findIndex((e) => e.id == this.idCarpetaActiva)
				this.idCarpetaActiva = null;
				this.carpetaSeleccionada = {}
				this.carpetas.splice(idx, 1)
				this.$refs.refModalEliminarCarpeta.toggle()
			} catch (error) {
				return this.error_catch(e)
			}
		},
		irDetalle(row) {
			this.$router.push({ name: 'tendero.cupones.detalles', params:{idCupon: row.id} })
		},
		funTipoCupon(tipo) {
			if (tipo === 1) {
				return "Aplicar Ya"
			}
			if (tipo === 2) {
				return "Bono Fúturo"
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.cupones-vendedor {
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 439px 1fr;
	grid-column-gap: 30px;
	grid-row-gap: 0px;
	grid-template-areas: "lista-carpetas lista-cupones";

	.lista-carpetas {
		grid-area: lista-carpetas;
	}

	.lista-cupones {
		grid-area: lista-cupones;
	}
}
</style>